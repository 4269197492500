import React from "react";
import { graphql } from "gatsby";
import PortableText from "../components/portableText";
import SEO from "../components/seo";
import CompatiblesContext from "./../components/context/compatibleContext";
import SeoContext from "../components/context/seoContext";

const FilterTemplate = (props) => {
  
  const { sanityPage, sanityFilter, sanitySiteSettings = {} } = props.data;
  const { _rawBody, seo } = sanityPage;
  var { title, subtitle, description, second_description, compatibles, filter_image, technical_image } = sanityFilter;

  sanitySiteSettings.pageTitle = `${title} | Filters | ${sanitySiteSettings.title || "Siguna"}`;

  if(second_description){
    // second_description = second_description.replaceAll("\n","</br>");
  }

  var fixedRawBody = JSON.stringify(_rawBody)
    .replace(/{{title}}/g, title || "")
    .replace(/{{subtitle}}/g, subtitle || "")
    .replace(/{{description}}/g, description || "")
    // .replace(/{{second_description}}/g, "<p>"+second_description+"</p>" || "")
    .replace(/{{filter_image}}/g, `filterImage:${filter_image?.asset?._id}`)
    .replace(/{{technical_image}}/g, `technicalImage:${technical_image?.asset?._id}`);

  
    
    var rbs = JSON.parse(fixedRawBody);
    var rbs_f = [];
    for (var i=0; i<rbs.length; i++){
      var rb = rbs[i];
      var ok = true;
      
     
    // console.log(rb);
      // if (rb.internalName == "Top"){
      //   if (!compatibles || compatibles.length<=0){
      //     rb.settings.radius.push("b");
      //     console.log(rb);
      //   }
      // }

      if (rb.internalName == "Technical description"){
        if (!technical_image){
          ok = false;
        }
        // for (var j=0; j<rb.content.length; j++){
        //   if (rb.content[j]._type == "customVariable"){
        //     if (rb.content[j].variable == "technicalImage:undefined"){
        //       // ok = false;
        //       rb.content = [];
        //     }
        //   }
        // }
      }
      if (rb.internalName == "Compatibles"){
        if (second_description){
          rb.settings.radius = Array();
        }
        if (!compatibles || compatibles.length<=0){
          ok = false;
        }
      }
      if (rb.internalName == "Second Description"){

        rb.content[0].text = second_description;
        if (!second_description){
          ok = false;
        }else{
          if (!technical_image){
            rb.settings.radius = Array();
          }
          for (var j=0; j<rb.content.length; j++){
            if (rb.content[j]._type == "secondDescription"){
              rb.content[j].dataText = second_description;
            }
          }
        }
        
      }
      if (rb.internalName == "Extended"){
        if (second_description && !technical_image){
          // ok = false;
          rb.settings.radius = Array();
        }
      }
      if (ok){
        rbs_f.push(rb);
      }
    }
    fixedRawBody = JSON.stringify(rbs_f);

  
  return (
    <SeoContext.Provider value={sanitySiteSettings}>
      <SEO title={title} description={description || seo?.description} keywords={seo?.tags}>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Product",
            "description": "${description || seo?.description}",
            "name": "${title}",
            "brand": "Siguna",
            "image": "${filter_image?.asset?.url}",
            "isRelatedTo": [${compatibles?.map(
              (c) => `{
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "${c}"
            }`
            )}],
            "offers": {
              "@type": "Offer",
              "price": "0"
            }
            "offers": {
              "@type": "AggregateOffer",
              "highPrice": "34",
              "lowPrice": "9",
              "availability": "https://schema.org/InStock",
              "priceCurrency": "EUR"
            }
          }`}
        </script>
      </SEO>
      <CompatiblesContext.Provider value={compatibles}>
        {_rawBody && <PortableText blocks={JSON.parse(fixedRawBody)} />}
      </CompatiblesContext.Provider>
    </SeoContext.Provider>
  );
};

export default FilterTemplate;
export const query = graphql`
  query ($id: String) {
    sanityPage(special: { eq: "filters" }) {
      title
      seo {
        description
        tags
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    sanityFilter(id: { eq: $id }) {
      title
      subtitle
      description
      second_description
      compatibles
      filter_image {
        asset {
          _id
          url
        }
      }
      technical_image {
        asset {
          _id
          url
        }
      }
    }
    sanitySiteSettings {
      ...Settings
    }
  }
`;
